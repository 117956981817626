<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <div v-bind:class="mobile ? 'contentMobile1' : 'content'">
      <v-row justify="center" no-gutters>
        <v-col v-bind:cols="mobile ? 10 : 6" class="text">
          <div v-html="$t('text1')"></div>
        </v-col>
        <v-col v-bind:cols="mobile ? 5 : 6">
          <v-img
              class="imgFirstSection"
              :src="require('@/assets/picto/pictoEphad.svg')"
              contain
              v-bind:height="mobile ? 175 : 300"
          />
        </v-col>
        <v-col v-bind:cols="mobile ? 10 : 12" class="textBlue">
          <div v-html="$t('text2')"></div>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center" v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
      <h2 v-html="$t('secondTitle')" v-bind:class="mobile ? 'secondTitleMobile' : 'secondTitle'"></h2>
      <v-col cols="5" style="text-align: right;" v-if="!mobile">
        <h3 v-html="$t('title1')"></h3>
        <div v-html="$t('text3')"></div>
        <br> <br> <br> <br> <br> <br> <br> <br> <br>
        <h3 v-html="$t('title3')"></h3>
        <div v-html="$t('text5')"></div>
      </v-col>
      <v-col v-bind:cols="mobile ? 1 : 2">
        <v-img
            :src="require('@/assets/picto/separator12.svg')"
            contain
            v-bind:height="mobile ? 400 : 300"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 9 : 5">
        <h3 v-html="$t('title1')" v-if="mobile"></h3>
        <div v-html="$t('text3')" v-if="mobile"></div>
        <br> <br> <br> <br> <br v-if="!mobile">
        <h3 v-html="$t('title2')"></h3>
        <div v-html="$t('text4')"></div>
        <br v-if="mobile"> <br v-if="mobile"> <br v-if="mobile"> <br v-if="mobile"> <br v-if="mobile"> <br v-if="mobile">
        <h3 v-html="$t('title3')" v-if="mobile"></h3>
        <div v-html="$t('text5')" v-if="mobile"></div>
      </v-col>
    </v-row>
    <div v-bind:class="mobile ? 'contentMobile2' : 'content'">
      <h2 v-html="$t('thirdTitle')" v-bind:class="mobile ? 'thirdTitleMobile' : 'thirdTitle'"></h2>
      <v-row justify="center" class="textBlue2" no-gutters  >
        <v-col cols="5" style="text-align: right;">
          <h3 v-html="$t('title4')"></h3>
          <div v-html="$t('text6')"></div>
          <br> <br> <br> <br> <br> <br> <br> <br> <br> <br> <br v-if="mobile"> <br v-if="mobile">
          <h3 v-html="$t('title6')"></h3>
          <div v-html="$t('text8')"></div>
          <br> <br> <br> <br> <br> <br> <br> <br> <br> <br> <br v-if="mobile"> <br v-if="mobile">
          <h3 v-html="$t('title8')"></h3>
          <div v-html="$t('text10')"></div>
        </v-col>
        <v-col cols="1">
          <v-img
              :src="require('@/assets/picto/separator13.svg')"
              contain
              height="650"
          />
        </v-col>
        <v-col cols="5" style="text-align: left;">
          <br> <br> <br> <br> <br>
          <h3 v-html="$t('title5')"></h3>
          <div class="text" v-html="$t('text7')"></div>
          <br> <br> <br> <br> <br> <br> <br v-if="mobile">
          <h3 v-html="$t('title7')"></h3>
          <div class="text" v-html="$t('text9')"></div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HealthInstitutionComp',
  props: ['mobile'],
  data: () => ({}),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Health institution",
    "secondTitle": "The association can support patients at each stage of their care journey:",
    "thirdTitle": "The typical pathways of the Via Vitaé athletic patient",
    "text1": "Physical activity and a sedentary lifestyle are major determinants of health. Even moderate physical activity plays an important role in the prevention and management of major chronic diseases as well as in improving mental health. <br> <br> Chronic diseases are at the origin of the first causes of death in Paca and among the 2 leading causes of preventable premature death. <br> The systematic use of physical activity as a preventive measure and as a non-drug therapy, aims to reduce the prevalence of chronic diseases and act on preventable premature mortality.",
    "text2": "Vitaé Sport Santé, supports many health establishments in the development of medico-sports courses. <br> Patients suffering from chronic diseases, obesity and psychiatric conditions can thus benefit from adapted physical activity programs, both as non-drug therapy in addition to the usual therapeutic arsenal, but also in prevention of complications.",
    "title1": "Phase 1: Treatment ",
    "title2": "Phase 2: Rehabilitation ",
    "title3": "Phase 3: Output ",
    "title4": "Initial assessment :",
    "title5": "1st step :",
    "title6": "Interim balance sheet :",
    "title7": "2nd step:",
    "title8": "Final balance sheet :",
    "text3": "The association offers adapted physical activities in health establishments ...",
    "text4": "The association supports patients during the rehabilitation phase",
    "text5": "The association initially offers adapted physical activity programs called bridging in order to support outgoing patients who often lack autonomy in their capacity ...",
    "text6": "Assessment of physical, social, and emotional health",
    "text7": "<strong> Bridging Program </strong> <br> - Mobilize Skills <br> - Build Capacity <br> - Develop Skills <br> - Increase Self-Esteem and Confidence",
    "text8": "Assessment of physical, social, and emotional health",
    "text9": "<strong> Referral to a sports or leisure association </strong> <br> - Foster and facilitate inclusion <br> - Reducing social boundaries <br> - Developing a common culture",
    "text10": "Assessment of physical, social, and emotional health"
  },
  "fr": {
    "mainTitle": "Etablissement de santé",
    "secondTitle": "L’association peut accompagner les patients lors de chaque étape de leur parcours de soin :",
    "thirdTitle": "Le parcours type du patient sportif Via Vitaé",
    "text1": "L’activité physique et la sédentarité sont des déterminants de santé majeurs. La pratique d’une activité physique, même modérée, joue un rôle important dans la prévention et la prise en charge des principales maladies chroniques ainsi que dans l’amélioration de la santé mentale. <br><br> Les maladies chroniques sont à l’origine des premières causes des décès en Paca et parmi les 2 premières causes de mortalité prématurée évitable. <br> Le recours systématique à l’activité physique en guise de prévention et en tant que thérapeutique non médicamenteuse, vise à diminuer cette prévalence des maladies chroniques et à agir sur la mortalité prématurée évitable.",
    "text2": "Vitaé Sport Santé, accompagne de nombreux établissement de santé dans le développement de parcours médico-sportif. <br> Les patients souffrant de maladies chroniques, obésité et affections psychiatriques peuvent ainsi bénéficier de programmes d’activités physiques adaptées, à la fois comme thérapeutique non médicamenteuse en complément de l’arsenal thérapeutique habituel, mais aussi en prévention des complications.",
    "title1": "Phase 1 : Soin  ",
    "title2": "Phase 2 : Rééducation  ",
    "title3": "Phase 3 : Sortie  ",
    "title4": "Bilan initial : ",
    "title5": "1ère étape :",
    "title6": "Bilan intermédiaire : ",
    "title7": "2ème étape :",
    "title8": "Bilan Final : ",
    "text3": "L’association propose des activités physiques adaptées dans les établissements de santé ...",
    "text4": "L’association accompagne les patients durant la phase de rééducation",
    "text5": "L’association propose dans un premier temps des programmes d’activité physique adaptée dits « passerelle » afin d’accompagner les patients sortant et souvent manquant d’autonomie dans leur capacité...",
    "text6": "Evaluation de la santé physique, sociale et émotionnelle",
    "text7": "<strong>Programme passerelle </strong><br> - Remobiliser les aptitudes<br> - Renforcer les capacités<br> - Développer les compétences<br> - Augmenter l’estime de soi et la confiance",
    "text8": "Evaluation de la santé physique, sociale et émotionnelle",
    "text9": "<strong>Orienter vers une association sportive ou de loisir</strong><br> - Favoriser et faciliter l’inclusion<br> - Réduire les frontières sociales<br> - Développer une culture commune",
    "text10": "Evaluation de la santé physique, sociale et émotionnelle"
  }
}
</i18n>

<style scoped>

.content {
  padding: 40px 200px 60px;
}

.contentMobile1 {
  font-size: 14px;
  padding-bottom: 40px;
}

.contentMobile2 {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  padding-bottom: 40px;
}

.text {
  color: #808080;
}

.textBlue {
  color: #1579A7;
}

.textBlue2 {
  color: #57939E;
}

.secondSection {
  color: #FFFFFF;
  background-color: #1579A7;
  padding-right: 200px;
  padding-left: 200px;
  padding-bottom: 60px;
}

.secondSectionMobile {
  font-size: 14px;
  color: #FFFFFF;
  background-color: #1579A7;
  padding-bottom: 40px;
}

.secondTitle {
  padding: 40px;
  font-family: Comfortaa, sans-serif;
}

.secondTitleMobile {
  font-size: 18px;
  padding: 35px;
  font-family: Comfortaa, sans-serif;
}

.thirdTitle {
  text-align: center;
  padding: 40px 40px 80px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.thirdTitleMobile {
  text-align: center;
  padding: 40px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

</style>
