<template>
  <div>
    <HealthInstitutionComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import HealthInstitutionComp from '@/components/desktop/HealthInstitution/HealthInstitutionComp.vue'

export default {
  name: 'HealthInstitution',
  props: ['mobile'],
  components: {
    HealthInstitutionComp,
  },
}
</script>
